import getAxiosInstance from "../config/http";
import qs from "qs";

export const LIST_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@LIST_ENROLMENT_CLAIM_REQUEST";
export const LIST_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@LIST_ENROLMENT_CLAIM_SUCCESS";
export const LIST_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@LIST_ENROLMENT_CLAIM_FAILURE";

export const listEnrolmentClaims = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ENROLMENT_CLAIM_REQUEST,
  });
  try {
    const queryString = qs.stringify(
      { ...params, _limit: params._limit || 600 },
      { encode: false }
    );
    const enrolmentRequestsData = await http.get(
      `/enrolment-requests?${queryString}`
    );
    dispatch({
      type: LIST_ENROLMENT_CLAIM_SUCCESS,
      payload: {
        listData: enrolmentRequestsData.data,
      },
    });
    return enrolmentRequestsData.data;
  } catch (e) {
    dispatch({
      type: LIST_ENROLMENT_CLAIM_FAILURE,
    });
  }
};

export const LIST_ENROLMENT_MEETING_TIMES_REQUEST =
  "ENROLMENT@LIST_ENROLMENT_MEETING_TIMES_REQUEST";
export const LIST_ENROLMENT_MEETING_TIMES_SUCCESS =
  "ENROLMENT@LIST_ENROLMENT_MEETING_TIMES_SUCCESS";
export const LIST_ENROLMENT_MEETING_TIMES_FAILURE =
  "ENROLMENT@LIST_ENROLMENT_MEETING_TIMES_FAILURE";

  export const listEnrolmentMeetingTimes = (params) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: LIST_ENROLMENT_MEETING_TIMES_REQUEST,
    });
    try {
      console.log("Before controller call", params);
      const enrolmentMeetingTimes = await http.get("/enrolment-requests/meeting-times", {
        params,
      });
      dispatch({
        type: LIST_ENROLMENT_CLAIM_SUCCESS,
        payload: {
          listData: enrolmentMeetingTimes.data,
        },
      });
      return enrolmentMeetingTimes.data;
    } catch (e) {
      console.log("Middleware catch error")
      console.log(e)
      dispatch({
        type: LIST_ENROLMENT_MEETING_TIMES_FAILURE,
      });
    }
  };

export const COUNT_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@COUNT_ENROLMENT_CLAIM_REQUEST";
export const COUNT_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@COUNT_ENROLMENT_CLAIM_SUCCESS";
export const COUNT_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@COUNT_ENROLMENT_CLAIM_FAILURE";

export const countEnrolmentClaims = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_ENROLMENT_CLAIM_REQUEST,
  });
  try {
    const count = await http.get("/enrolment-requests/count", {
      params,
    });
    dispatch({
      type: COUNT_ENROLMENT_CLAIM_SUCCESS,
      payload: {
        total: count.data,
      },
    });
    return count.data;
  } catch (e) {
    dispatch({
      type: COUNT_ENROLMENT_CLAIM_FAILURE,
    });
  }
};

export const FETCH_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@FETCH_ENROLMENT__CLAIM_REQUEST";
export const FETCH_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@FETCH_ENROLMENT_CLAIM_SUCCESS";
export const FETCH_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@FETCH_ENROLMENT_CLAIM_FAILURE";

export const fetchEnrolmentClaim = (enrolmentClaimId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ENROLMENT_CLAIM_REQUEST,
  });
  try {
    const enrollmentRequestData = await http.get(
      `/enrolment-requests/${enrolmentClaimId}`
    );
    dispatch({
      type: FETCH_ENROLMENT_CLAIM_SUCCESS,
      payload: {
        enrolmentRequestData: enrollmentRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_ENROLMENT_CLAIM_FAILURE,
    });
  }
};

export const fetchEnrolmentClaimByEmbg =
  ({ embg, id }) =>
  async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: FETCH_ENROLMENT_CLAIM_REQUEST,
    });
    try {
      const enrollmentRequestData = await http.get(`/enrolment-requests`, {
        params: {
          embg,
          educational_facility: id,
        },
      });
      dispatch({
        type: FETCH_ENROLMENT_CLAIM_SUCCESS,
        payload: {
          enrolmentRequestData: enrollmentRequestData.data,
        },
      });
      return enrollmentRequestData.data[0];
    } catch (error) {
      dispatch({
        type: FETCH_ENROLMENT_CLAIM_FAILURE,
      });
    }
  };

export const UPDATE_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@UPDATE_ENROLMENT_CLAIM_REQUEST";
export const UPDATE_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@UPDATE_ENROLMENT_CLAIM_SUCCESS";
export const UPDATE_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@UPDATE_ENROLMENT_CLAIM_FAILURE";

export const updateEnrolmentClaim =
  (id, file, enrolmentClaimData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_ENROLMENT_CLAIM_REQUEST,
    });
    try {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      file && formData.append("files.dropOutDoc", file);
      formData.append(
        "data",
        JSON.stringify({
          ...enrolmentClaimData,
        })
      );
      const data = await http.put(`/enrolment-requests/${id}`, formData);
      dispatch({
        type: UPDATE_ENROLMENT_CLAIM_SUCCESS,
        payload: {
          listData: data,
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_ENROLMENT_CLAIM_FAILURE,
      });
    }
  };

export const updateEnrolmentClaimWeb =
  (id, enrolmentClaimData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_ENROLMENT_CLAIM_REQUEST,
    });
    try {
      const data = await http.put(
        `/enrolment-requests/${id}`,
        enrolmentClaimData
      );
      dispatch({
        type: UPDATE_ENROLMENT_CLAIM_SUCCESS,
        payload: {
          listData: data,
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_ENROLMENT_CLAIM_FAILURE,
      });
    }
  };

export const DELETE_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_REQUEST";
export const DELETE_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_SUCCESS";
export const DELETE_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_FAILURE";

export const deleteEnrolmentClaim = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ENROLMENT_CLAIM_REQUEST,
  });
  try {
    const data = await http.delete(`/enrolment-requests/${id}`);
    dispatch({
      type: DELETE_ENROLMENT_CLAIM_SUCCESS,
      payload: {
        enrolmentId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_ENROLMENT_CLAIM_FAILURE,
    });
  }
};

export const DELETE_ALL_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_REQUEST";
export const DELETE_ALL_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_SUCCESS";
export const DELETE_ALL_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@DELETE_ENROLMENT_CLAIM_FAILURE";

export const deleteAllEnrolmentClaims =
  ({ schoolId }) =>
  async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: DELETE_ALL_ENROLMENT_CLAIM_REQUEST,
    });
    try {
      console.log("schoolID", schoolId);
      await http.delete(`/enrolments/delete/all/${schoolId}`);
      dispatch({
        type: DELETE_ALL_ENROLMENT_CLAIM_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: DELETE_ALL_ENROLMENT_CLAIM_FAILURE,
      });
    }
  };

export const CREATE_ENROLMENT_CLAIM_REQUEST =
  "ENROLMENT@CREATE_ENROLMENT_CLAIM_REQUEST";
export const CREATE_ENROLMENT_CLAIM_SUCCESS =
  "ENROLMENT@CREATE_ENROLMENT_CLAIM_SUCCESS";
export const CREATE_ENROLMENT_CLAIM_FAILURE =
  "ENROLMENT@CREATE_ENROLMENT_CLAIM_FAILURE";

export const createEnrolmentClaim =
  (enrolmentRequestData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: CREATE_ENROLMENT_CLAIM_REQUEST,
    });
    try {
      const { data } = await http.post(
        `/enrolment-requests`,
        enrolmentRequestData
      );
      dispatch({
        type: CREATE_ENROLMENT_CLAIM_SUCCESS,
        payload: {
          enrolmentRequestData: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({
        type: CREATE_ENROLMENT_CLAIM_FAILURE,
      });
    }
  };

export const SET_CANCELATION_FLOW = "ENROLMENT@SET_CANCELATION_FLOW";

export const setCancelationFlow = (meeting) => (dispatch) => {
  dispatch({
    type: SET_CANCELATION_FLOW,
    payload: {
      meeting,
    },
  });
};

export const RESET_ENROLMENT_CLAIM = "ENROLMENT@RESET_ENROLMENT_CLAIM";

export const resetEnrolmentRequest = () => (dispatch) => {
  dispatch({
    type: RESET_ENROLMENT_CLAIM,
  });
};

export const SET_ENROLMENT_DOCTYPE_SUBMITION =
  "ENROLMENT@SET_ENROLMENT_DOCTYPE_SUBMITION";

export const setEnrolmentDocTypeSubmition =
  (docsSubmitionType) => (dispatch) => {
    dispatch({
      type: SET_ENROLMENT_DOCTYPE_SUBMITION,
      payload: {
        docsSubmitionType,
      },
    });
  };

export const SET_ENROLMENT_CHOSEN_DATE = "ENROLMENT@SET_ENROLMENT_CHOSEN_DATE";

export const setEnrolmentChosenDate = (chosenDate) => (dispatch) => {
  dispatch({
    type: SET_ENROLMENT_CHOSEN_DATE,
    payload: {
      chosenDate,
    },
  });
};
